var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_vm._v(" Click a BaseSpace project to see and add samples from that project "),_c('v-data-table',{attrs:{"headers":_vm.projectHeaders,"items":_vm.projects,"total-items":_vm.projectsTotalCount,"loading":_vm.projectsLoading,"pagination":_vm.projectPagination,"rows-per-page-items":_vm.projectRowsPerPageItems,"must-sort":"","expand":false,"item-key":"Id"},on:{"update:pagination":function($event){_vm.projectPagination=$event}},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('tr',{class:{
          ['table-row']: true,
          grey: props.expanded,
          ['lighten-5']: props.expanded,
        },on:{"click":function($event){return _vm.expand(props)}}},[_c('td',[_vm._v(" "+_vm._s(props.item.Name)+" "),(props.item.TotalSize <= 0)?_c('div',{staticClass:"error--text"},[_vm._v(" Project unavailable because it has no data ")]):_vm._e()]),_c('td',[_vm._v(_vm._s(_vm._f("formatTime")(props.item.DateModified)))]),_c('td',[_c('v-layout',{attrs:{"row":""}},[(props.expanded)?_c('v-btn',{staticClass:"add-all text-capitalize",attrs:{"flat":"","outline":"","disabled":_vm.uploader.inProgress.some(
                  (a) => a.baseSpaceProjectId === props.item.Id,
                )},on:{"click":function($event){return _vm.selectAll($event, props.item.Id, props.item.Name)}}},[_vm._v("Select All ("+_vm._s(_vm.projectSamples.filter(_vm.checkValidSample).length)+")")]):_vm._e(),(props.expanded)?_c('v-btn',{staticClass:"add-all text-capitalize",attrs:{"flat":"","outline":""},on:{"click":function($event){return _vm.toggleShowSampleSearch($event)}}},[_c('v-icon',[_vm._v("search")])],1):_vm._e()],1)],1)])]}},{key:"expand",fn:function(props){return [_c('div',{staticClass:"sample-table grey lighten-5"},[_c('v-layout',{staticClass:"ml-5",attrs:{"align-center":""}},[_c('v-spacer'),(_vm.showSampleSearch)?_c('v-text-field',{staticClass:"mr-4 mt-0 mb-2 sample-search-input",attrs:{"append-icon":"search","label":"Sample Name (must be exact)","single-line":"","hide-details":""},model:{value:(_vm.sampleSearch),callback:function ($$v) {_vm.sampleSearch=$$v},expression:"sampleSearch"}}):_vm._e()],1),_c('v-data-table',{staticClass:"ml-5",attrs:{"headers":_vm.sampleHeaders,"items":_vm.projectSamples,"total-items":_vm.projectSamplesTotalCount,"loading":_vm.projectSamplesLoading,"pagination":_vm.samplePagination,"rows-per-page-items":_vm.sampleRowsPerPageItems,"must-sort":"","expand":"","item-key":"Id"},on:{"update:pagination":function($event){_vm.samplePagination=$event}},scopedSlots:_vm._u([{key:"items",fn:function(sample){return [_c('tr',{staticClass:"grey lighten-5"},[_c('td',[_vm._v(_vm._s(sample.item.BioSampleName))]),_c('td',[_vm._v(_vm._s(_vm._f("formatTime")(sample.item.DateModified)))]),_c('td',{staticClass:"text-xs-center"},[(
                    !_vm.uploader.succeeded.some(
                      (a) => a.basespaceSampleId === sample.item.Id,
                    ) && _vm.checkValidSample(sample.item)
                  )?_c('v-btn',{attrs:{"flat":"","icon":"","disabled":_vm.uploader.inProgress.some(
                      (a) => a.basespaceSampleId === sample.item.Id,
                    )},on:{"click":function($event){return _vm.select(
                      props.item.Id,
                      props.item.Name,
                      sample.item.Id,
                      sample.item.BioSampleName,
                    )}}},[_c('v-icon',[_vm._v("add")])],1):(!_vm.checkValidSample(sample.item))?_c('PopOut',{attrs:{"icon":"warning","icon-color":"orange"}},[_vm._v("No datasets were found for this sample in BaseSpace")]):_c('v-icon',{staticClass:"green--text"},[_vm._v("check")])],1)])]}}],null,true)})],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
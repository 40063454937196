<template>
  <div>
    <div class="subheading my-3">
      When a user selects this lab on the data submission form, these defaults will be selected. You can also enforce that the submitter must use these options.
    </div>
    <v-form>
      <div>
        <div class="subheading font-weight-bold">Platform</div>
        <v-select
          name="platform"
          :items="platformsWithNull"
          v-model="defaultPlatform"
          @change="platformUpdated"
          class="input-field"
        ></v-select>
        <v-checkbox
          v-model="defaultPlatformEnforced"
          label="Force submitters to use this option"
          :disabled="defaultPlatform === null"
        ></v-checkbox>
      </div>
      <div v-if="defaultPlatform && kits.has(defaultPlatform)">
        <div class="subheading font-weight-bold">Device & Chemistry</div>
        <v-select
          name="kit"
          :items="getKits(defaultPlatform)"
          v-model="defaultKit"
          @change="kitUpdated"
          class="input-field"
        ></v-select>
        <v-checkbox
          v-model="defaultKitEnforced"
          label="Force submitters to use this option"
          :disabled="defaultKit === null"
        ></v-checkbox>
      </div>
      <div>
        <div class="subheading font-weight-bold">Metagenomic Database</div>
        <v-select
          name="metagenomicDatabase"
          :items="metagenomicDatabasesWithNull"
          v-model="defaultMetagenomicDatabase"
          @change="metagenomicDatabaseUpdated"
          class="input-field"
        ></v-select>
        <v-checkbox
          v-model="defaultMetagenomicDatabaseEnforced"
          label="Force submitters to use this option"
          :disabled="defaultMetagenomicDatabase === null"
        ></v-checkbox>
      </div>
      <div>
        <div class="subheading font-weight-bold">Sample Type</div>
        <v-select
          name="sampleType"
          :items="sampleTypesWithNull"
          v-model="defaultSampleType"
          @change="sampleTypeUpdated"
          class="input-field"
        ></v-select>
        <v-checkbox
          v-model="defaultSampleTypeEnforced"
          label="Force submitters to use this option"
          :disabled="defaultSampleType === null"
        ></v-checkbox>
      </div>
      <div>
        <div class="subheading font-weight-bold">Sequenced Material</div>
        <v-select
          name="moleculeType"
          :items="moleculeTypesWithNull"
          v-model="defaultMoleculeType"
          @change="moleculeTypeUpdated"
          class="input-field"
        ></v-select>
        <v-checkbox
          v-model="defaultMoleculeTypeEnforced"
          label="Force submitters to use this option"
          :disabled="defaultMoleculeType === null"
        ></v-checkbox>
      </div>
      <v-btn
        class="ml-0 text-capitalize"
        @click="updateSubmissionDefaults()"
        color="primary"
        :disabled="errors.items.length > 0 || disabled"
      >
        Save
      </v-btn>
    </v-form>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import {
  dispatchUpdateLab,
  dispatchGetLabMembership,
} from "@bugseq-site/app/src/store/modules/api/actions";
import { components } from '@bugseq-site/app/src/lib/api/api'
import {
  ComboboxField,
  moleculeTypes,
  sampleTypes,
  platforms,
  kits,
} from "@bugseq-site/app/src/lib/types";

const ComponentProps = Vue.extend({
  props: {
    lab: Object,
    disabled: Boolean,
  },
});

function addUnsetNull<Type>(vals: Array<ComboboxField<Type>>): Array<ComboboxField<Type | null>> {
  return ([
    { text: "Unset - no default", value: null },
  ] as Array<ComboboxField<Type | null>>).concat(vals)
}

@Component({
  data() {
    return {
      kits,
    }
  }
})
export default class LabTabAnalysisConfigurationSubmissionDefaults extends ComponentProps {
  private defaultMoleculeType: components["schemas"]["MoleculeType"] | null = null
  private defaultMoleculeTypeEnforced: boolean = false
  private defaultPlatform: components["schemas"]["Platform"] | null = null
  private defaultPlatformEnforced: boolean = false
  private defaultKit: components["schemas"]["Kit"] | null = null
  private defaultKitEnforced: boolean = false
  private defaultMetagenomicDatabase: components["schemas"]["MetagenomicDatabase"] | null = null
  private defaultMetagenomicDatabaseEnforced: boolean = false
  private defaultSampleType: components["schemas"]["app__models__job_run_options__SampleType"] | null = null
  private defaultSampleTypeEnforced: boolean = false

  private moleculeTypesWithNull = addUnsetNull(moleculeTypes)
  private platformsWithNull = addUnsetNull(platforms)
  private metagenomicDatabasesWithNull = addUnsetNull(
    [
      { text: "BugRef Curated DB", value: "BUGSEQ_DEFAULT" },
      { text: "NCBI core_nt", value: "NCBI_NT" },
    ] as Array<ComboboxField<components['schemas']['MetagenomicDatabase']>>
  )
  private sampleTypesWithNull = addUnsetNull(sampleTypes)

  private mounted() {
    if (this.lab.default_molecule_type) {
      this.defaultMoleculeType = this.lab.default_molecule_type
      this.defaultMoleculeTypeEnforced = this.lab.default_molecule_type_enforced
    }
    if (this.lab.default_platform) {
      this.defaultPlatform = this.lab.default_platform
      this.defaultPlatformEnforced = this.lab.default_platform_enforced
    }
    if (this.lab.default_kit) {
      this.defaultKit = this.lab.default_kit
      this.defaultKitEnforced = this.lab.default_kit_enforced
    }
    if (this.lab.default_metagenomic_database) {
      this.defaultMetagenomicDatabase = this.lab.default_metagenomic_database
      this.defaultMetagenomicDatabaseEnforced = this.lab.default_metagenomic_database_enforced
    }
    if (this.lab.default_sample_type) {
      this.defaultSampleType = this.lab.default_sample_type
      this.defaultSampleTypeEnforced = this.lab.default_sample_type_enforced
    }
  }

  private moleculeTypeUpdated(val: components["schemas"]["MoleculeType"] | null) {
    if (!val) {
      this.defaultMoleculeTypeEnforced = false
    }
  }

  private platformUpdated(val: components["schemas"]["Platform"] | null) {
    if (!val) {
      this.defaultPlatformEnforced = false
    }

    this.defaultKit = null
    this.kitUpdated(this.defaultKit)
  }

  private getKits(platform: components["schemas"]["Platform"]): Array<ComboboxField<components["schemas"]["Kit"] | null>> {
    const kitsForPlatform = kits.get(platform)
    if (!kitsForPlatform) {
      return []
    }

    return addUnsetNull(kitsForPlatform)
  }

  private kitUpdated(val: components["schemas"]["Kit"] | null) {
    if (!val) {
      this.defaultKitEnforced = false;
    }
  }

  private metagenomicDatabaseUpdated(val: components["schemas"]["MetagenomicDatabase"] | null) {
    if (!val) {
      this.defaultMetagenomicDatabaseEnforced = false;
    }
  }

  private sampleTypeUpdated(val: components["schemas"]["app__models__job_run_options__SampleType"] | null) {
    if (!val) {
      this.defaultSampleTypeEnforced = false;
    }
  }

  private async updateSubmissionDefaults() {
    await dispatchUpdateLab(this.$store, {
      id: this.lab.id,
      lab: {
        default_molecule_type: this.defaultMoleculeType,
        default_molecule_type_enforced: this.defaultMoleculeTypeEnforced,
        default_platform: this.defaultPlatform,
        default_platform_enforced: this.defaultPlatformEnforced,
        default_kit: this.defaultKit,
        default_kit_enforced: this.defaultKitEnforced,
        default_metagenomic_database: this.defaultMetagenomicDatabase,
        default_metagenomic_database_enforced: this.defaultMetagenomicDatabaseEnforced,
        default_sample_type: this.defaultSampleType,
        default_sample_type_enforced: this.defaultSampleTypeEnforced,
      },
    });
    await dispatchGetLabMembership(this.$store); // refresh, because the resp of create doesn't have members
  }
}
</script>

<style scoped>
.input-field {
  max-width: 30em;
}
</style>

<template>
  <div class="pa-4">
      <div class="pb-2">
        <v-btn
          class="text-capitalize mx-0 pl-0 pr-2"
          flat
          to="/app/main/labs"
          active-class=""
        ><v-icon>keyboard_double_arrow_left</v-icon>Back to Labs</v-btn>
      </div>
    <div class="pb-3 headline">
      Create a New Lab
    </div>
    <div v-if="remainingSeatCount === 0">
      <div class="subheading mb-4">
        Labs allow you to collaborate with other team members. You can
        share analyses with members of your lab so that they can also access
        results in the BugSeq portal. Phylogenetic analyses will also
        incorporate previously analyzed samples from your lab.
      </div>
      <v-alert
        :value="true"
        class="font-weight-bold"
        type="info"
        outline
      >
        Please <a :href="quoteUrl">request a quote</a> for a plan that includes Lab members.
      </v-alert>
      <v-btn
        color="primary"
        class="text-capitalize ml-0 mt-3"
        :href="quoteUrl"
      >Request a Quote</v-btn>
    </div>
    <div v-else>
      <div class="subheading mt-3">
        <b>Name</b>
      </div>
      <v-text-field
        label="My Great Lab"
        v-model="labName"
        single-line
      ></v-text-field>
      <div class="subheading mt-3">
        <b>Your Role</b>
      </div>
      <div class="my-2 font-italic">Read more about lab roles <a href="https://docs.bugseq.com/labs/#roles" target="_blank">here</a>.</div>
      <v-radio-group v-model="managerRole">
        <v-radio
          v-for="[memberRoleValue, memberRoleKey] in memberRoles"
          :key="memberRoleKey"
          :label="memberRoleKey"
          :value="memberRoleValue"
        ></v-radio>
      </v-radio-group>
      <div class="subheading mt-3">
        <b>Lab Settings</b>
      </div>
      <div class="my-2 font-italic">These can be updated later. Read more about lab settings <a href="https://docs.bugseq.com/labs/#lab-settings" target="_blank">here</a>.</div>
      <div>
        <v-checkbox
          v-model="autoShareAnalyses"
          label="Automatically Share Analyses with Lab Members"
        >
          <template slot="label">
            <span class="mr-2">
              Automatically Share Analyses with Lab Members </span
            ><PopOut icon="help"
              >When any analysis completes, all lab members will get an email with a
              link to access results.</PopOut
            >
          </template>
        </v-checkbox>
      </div>
      <v-btn
        color="primary"
        class="text-capitalize ml-0"
        v-on:click="createLab()"
        :disabled="!remainingSeatCount || !labName"
        >Create</v-btn
      >
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { components } from '@bugseq-site/app/src/lib/api/api'
import {
  dispatchCreateLab,
  dispatchGetLabMembership,
  dispatchGetUserCredits,
} from "@bugseq-site/app/src/store/modules/api/actions";
import { readLabs, readUserProfile } from "@bugseq-site/app/src/store/modules/api/getters";
import PopOut from "@bugseq-site/shared/src/components/PopOut.vue";
import { getWwwUrl } from '@bugseq-site/shared/src/env'

enum MemberRole {
  SUBMITTER = "SUBMITTER",
  REVIEWER = "REVIEWER",
}

@Component({
  components: {
    PopOut,
  },
  data() {
    return {
      MemberRole,
    };
  }
})
export default class LabNew extends Vue {
  private labName: string = "";
  private memberRoles: Map<components['schemas']['OrgMemberRole'], string> = new Map([
    [MemberRole.SUBMITTER, "Submitter"],
    [MemberRole.REVIEWER, "Reviewer"],
  ]);
  private managerRole: components['schemas']['OrgMemberRole'] = MemberRole.SUBMITTER;
  private autoShareAnalyses: boolean = false
  private remainingSeatCount: number | null = null;

  private quoteUrl: string = `${getWwwUrl()}/quote`

  private async mounted() {
    const user = readUserProfile(this.$store)
    const userCreditsResponse = await dispatchGetUserCredits(
      this.$store,
      {
        billingAccountId: user!.billing_account_id,
        params: {
          remaining_count_gt: 0,
          include_expired: false,
        },
      }
    )
    this.remainingSeatCount = userCreditsResponse!.items.reduce((count, curr) => count + curr.remaining_count, 0)
  }

  private async createLab() {
    const lab = await dispatchCreateLab(this.$store, { name: this.labName, manager_role: this.managerRole, auto_share_analyses: this.autoShareAnalyses });
    this.labName = "";
    this.managerRole = MemberRole.REVIEWER;
    await dispatchGetLabMembership(this.$store);
    await this.$router.push({ name: "labview", params: { id: lab!.id } })
  }
}
</script>

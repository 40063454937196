/* global File, Blob */
/* eslint @typescript-eslint/no-non-null-assertion: 0 */
import createClient from 'openapi-fetch'
import { paths } from '@bugseq-site/app/src/lib/api/api'
import { getApiUrl } from '@bugseq-site/shared/src/env'
import { getAuthHeaders } from '@bugseq-site/shared/src/lib/api/auth'
import { BugSeqApiError, isRawBugSeqApiError } from '@bugseq-site/shared/src/lib/api/errors'

import { AsyncProcessor } from '@bugseq-site/app/src/lib/asyncprocessor'
import { formatSize } from '@bugseq-site/shared/src/lib/utils'

const { POST } = createClient<paths>({ baseUrl: getApiUrl() })

const MAX_CHUNK_SIZE_BYTES: number = 8 * 1024 * 1024

abstract class Upload {
  public id: number

  public fileMeta: { name: string, size: number, uploaded: number }
  public bugseqId?: string
  public err?: string

  protected file: File | undefined
  protected region: string
  protected academic: boolean

  constructor (
    id: number,
    file: File,
    filename: string,
    region: string,
    academic: boolean = false
  ) {
    this.id = id
    this.file = file
    this.fileMeta = {
      name: filename,
      size: file.size,
      uploaded: 0
    }
    this.region = region
    this.academic = academic
  }

  public toString (): string {
    return `${this.fileMeta.name} (${formatSize(this.fileMeta.size)})`
  }

  public abstract execute ()

  public complete (): void {
    // allow the file to be gc'd (not sure if this does anything)
    this.file = undefined
  }
}

class SingleUpload extends Upload {
  private uploadData: any

  constructor (
    id: number,
    file: File,
    filename: string,
    region: string,
    academic: boolean = false
  ) {
    super(id, file, filename, region, academic)
  }

  public async execute (): Promise<void> {
    let data, error
    if (this.academic) {
      ({ data, error } = await POST('/v1/academic/files/singlepart/init', {
        body: {
          filename: this.fileMeta.name,
          s3_region: this.region
        }
      }))
    } else {
      ({ data, error } = await POST('/v1/files/singlepart/init', {
        headers: await getAuthHeaders(),
        body: {
          filename: this.fileMeta.name,
          s3_region: this.region
        }
      }))
    }

    if (error !== undefined) {
      if (isRawBugSeqApiError(error)) {
        throw new BugSeqApiError(error.detail)
      }

      throw error
    }

    this.uploadData = data
    this.bugseqId = this.uploadData.id

    const body = new FormData()

    Object.entries(this.uploadData.presigned_s3_data.fields).forEach(
      ([key, value]) => {
        body.append(key, value as string)
      }
    )
    body.append('file', this.file!)

    const uploadResp = await fetch(this.uploadData.presigned_s3_data.url, {
      method: 'POST',
      body
    })
    if (!uploadResp.ok) {
      throw new Error('Upload error: unknown')
    }

    this.fileMeta.uploaded = this.file!.size

    this.complete()
  }
}

interface ChunkResult {
  ETag: string
  PartNumber: number
  size: number
}

class Chunk {
  private readonly fileId: string
  private readonly uploadId: string
  private readonly partNumber: number
  private readonly blob: Blob
  private readonly academic: boolean

  constructor (
    fileId: string,
    uploadId: string,
    partNumber: number,
    blob: Blob,
    academic: boolean = false
  ) {
    this.fileId = fileId
    this.uploadId = uploadId
    this.partNumber = partNumber
    this.blob = blob
    this.academic = academic
  }

  public async execute (): Promise<ChunkResult> {
    let data, error
    if (this.academic) {
      ({ data, error } = await POST('/v1/academic/files/multipart/chunk', {
        body: {
          file_id: this.fileId,
          upload_id: this.uploadId,
          part_number: this.partNumber
        }
      }))
    } else {
      ({ data, error } = await POST('/v1/files/multipart/chunk', {
        headers: await getAuthHeaders(),
        body: {
          file_id: this.fileId,
          upload_id: this.uploadId,
          part_number: this.partNumber
        }
      }))
    }
    if (error !== undefined) {
      if (isRawBugSeqApiError(error)) {
        throw new BugSeqApiError(error.detail)
      }

      throw error
    }

    const uploadResp = await fetch(
      data.presigned_s3_data,
      {
        method: 'PUT',
        body: this.blob
      }
    )
    if (!uploadResp.ok) {
      throw new Error('Upload error: unknown')
    }

    const etag = uploadResp.headers.get('ETag')
    if (etag === null) {
      throw new Error('etag not present in headers')
    }
    return {
      ETag: etag,
      PartNumber: this.partNumber,
      size: this.blob.size
    }
  }
}

class ChunkedUpload extends Upload {
  private readonly numChunks: number
  private uploadData: any
  private uploadedChunks: ChunkResult[]
  private readonly executor: AsyncProcessor<ChunkResult>

  constructor (
    id: number,
    file: File,
    filename: string,
    region: string,
    executor: AsyncProcessor<ChunkResult>,
    academic: boolean = false
  ) {
    super(id, file, filename, region, academic)
    this.numChunks = Math.floor(file.size / MAX_CHUNK_SIZE_BYTES) + 1
    this.uploadedChunks = [] // we reset on the next line, but this makes the linter happy
    this.reset()
    this.executor = executor
  }

  public reset (): void {
    this.uploadedChunks = []
  }

  public async execute (): Promise<void> {
    this.reset() // for retries, we need to reset every time

    let data, error
    if (this.academic) {
      ({ data, error } = await POST('/v1/academic/files/multipart/init', {
        body: {
          filename: this.fileMeta.name,
          s3_region: this.region
        }
      }))
    } else {
      ({ data, error } = await POST('/v1/files/multipart/init', {
        headers: await getAuthHeaders(),
        body: {
          filename: this.fileMeta.name,
          s3_region: this.region
        }
      }))
    }
    if (error !== undefined) {
      if (isRawBugSeqApiError(error)) {
        throw new BugSeqApiError(error.detail)
      }

      throw error
    }
    this.uploadData = data
    this.bugseqId = this.uploadData.id

    const chunkPromises: Array<Promise<ChunkResult>> = []

    for (let part = 0; part < this.numChunks; part++) {
      const start = part * MAX_CHUNK_SIZE_BYTES
      const partNumber = part + 1
      const end = partNumber * MAX_CHUNK_SIZE_BYTES
      const blob =
        partNumber < this.numChunks
          ? this.file!.slice(start, end)
          : this.file!.slice(start)

      // do chunked upload
      const chunk = new Chunk(
        this.uploadData.id,
        this.uploadData.presigned_s3_data.UploadId,
        partNumber,
        blob,
        this.academic
      )

      chunkPromises.push(
        this.executor.execute(chunk).then((result: ChunkResult) => {
          this.fileMeta.uploaded += result.size
          return result
        })
      )
    }

    for (const chunkPromise of chunkPromises) {
      const result = await chunkPromise
      this.uploadedChunks.push(result)
    }

    if (this.academic) {
      await POST('/v1/academic/files/multipart/complete', {
        body: {
          file_id: this.uploadData.id,
          upload_id: this.uploadData.presigned_s3_data.UploadId,
          parts: this.uploadedChunks.map(({ ETag, PartNumber }) => ({
            ETag,
            PartNumber
          })) // strip out extraneous fields
        }
      })
    } else {
      await POST('/v1/files/multipart/complete', {
        headers: await getAuthHeaders(),
        body: {
          file_id: this.uploadData.id,
          upload_id: this.uploadData.presigned_s3_data.UploadId,
          parts: this.uploadedChunks.map(({ ETag, PartNumber }) => ({
            ETag,
            PartNumber
          })) // strip out extraneous fields
        }
      })
    }

    this.complete()
  }
}

const filenameForbiddenChars = /[^A-Za-z0-9.\-_/ ]+/ // Only allow letters, numbers, ., -, _, and space
const filenameAllowedFirstChars = /^[A-Za-z0-9]/ // First character must be a letter or number

function validateFilename (filename: string): boolean {
  if (filenameForbiddenChars.test(filename)) {
    return false
  }
  if (!filenameAllowedFirstChars.test(filename.charAt(0))) {
    return false
  }
  return true
}

export class Uploader {
  public inProgress: Upload[]
  public succeeded: Upload[]
  public failed: Upload[]

  private readonly region: string
  private readonly acceptedExtensions: string[]
  private readonly executor: AsyncProcessor<any>
  private readonly chunkExecutor: AsyncProcessor<ChunkResult>
  private readonly academic: boolean

  private counter: number = 0

  constructor (
    region: string = 'ca-central-1',
    acceptedExtensions: string[] = [],
    academic: boolean = false
  ) {
    this.region = region
    this.acceptedExtensions = acceptedExtensions
    this.academic = academic

    this.executor = new AsyncProcessor(
      3, // concurrency
      3, // retries
      100, // minBackoffMs
      10000, // maxBackoffMs
      4, // backoffCoefficient
      500, // backoffJitterMs
      100 // maxTotalErrors
    )

    this.chunkExecutor = new AsyncProcessor(
      3, // concurrency
      3, // retries
      100, // minBackoffMs
      10000, // maxBackoffMs
      4, // backoffCoefficient
      500, // backoffJitterMs
      100 // maxTotalErrors
    )

    this.inProgress = []
    this.succeeded = []
    this.failed = []
  }

  public async upload (file: File, filename: string): Promise<void> {
    let upload
    const id = this.counter++

    if (file.size < MAX_CHUNK_SIZE_BYTES) {
      upload = new SingleUpload(id, file, filename, this.region, this.academic)
    } else {
      upload = new ChunkedUpload(
        id,
        file,
        filename,
        this.region,
        this.chunkExecutor,
        this.academic
      )
    }

    // this is intentionally after creating an upload
    // because it keeps a common format for failed array.
    const errMessage = this.getErrorMessage(file, filename)
    if (errMessage !== undefined) {
      upload.err = errMessage
      this.failed.push(upload)
      return
    }

    try {
      this.inProgress.push(upload)
      await this.executor.execute(upload)
      this.succeeded.push(upload)
    } catch (e) {
      // tslint:disable: no-console
      console.error(e)

      if (e instanceof Error) {
        upload.err = e.message
      } else {
        upload.err = e
      }

      this.failed.push(upload)
    } finally {
      const index = this.inProgress.indexOf(upload)
      if (index !== -1) {
        this.inProgress.splice(index, 1)
      }
    }
  }

  public deleteSucceeded (id: number): void {
    this.succeeded = this.succeeded.filter((f) => f.id !== id)
  }

  public deleteFailed (id: number): void {
    this.failed = this.failed.filter((f) => f.id !== id)
  }

  private trimFileExtensions (fname: string): string {
    let trimmed = fname
    for (const knownSuffix of this.acceptedExtensions) {
      if (trimmed.endsWith(knownSuffix)) {
        trimmed = trimmed.substring(0, trimmed.length - knownSuffix.length)
      }
    }

    return trimmed
  }

  private getErrorMessage (file: File, filename: string): string | undefined {
    if (file.size === 0) {
      return `${file.name} appears to be empty. Files cannot be empty.`
    }

    // check name meets requirements
    if (!validateFilename(file.name)) {
      return `Filenames must only contain letters, numbers, periods, dashes, underscores, and must begin with a letter or number. Inavlid filename: '${file.name}'`
    }

    // check ext
    let allowed = false
    if (this.acceptedExtensions.length > 0) {
      for (const suffix of this.acceptedExtensions) {
        if (file.name.endsWith(suffix)) {
          allowed = true
        }
      }

      if (!allowed) {
        return `${
          file.name
        } has unsupported file type. Supported file types: ${this.acceptedExtensions.join(
          ', '
        )}.`
      }
    }

    const trimmedFilenames = this.inProgress
      .concat(this.succeeded)
      .concat(this.failed)
      .map((f) => f.fileMeta.name)
      .map((f) => this.trimFileExtensions(f))

    if (trimmedFilenames.includes(this.trimFileExtensions(filename))) {
      return `It looks like you have already attempted to upload ${file.name}. Filenames must be unique, even without the file extension. Please remove the file, rename it and try again if you intended to upload it twice. If you did not intend to upload the file, please remove it from the "Failed" section.`
    }
  }
}

<template>
  <div class="pa-4 mt-4">
    <v-alert
      :value="!isLabManager()"
      class="font-weight-bold my-3"
      type="info"
      outline
    >
      Only lab managers can update settings for a lab
    </v-alert>
    <div class="subheading font-weight-bold">
      Metadata
    </div>
    <div class="px-4 mt-2 mb-5">
      <LabTabAnalysisConfigurationMetadataKeys :lab="lab" :disabled="!isLabManager()"></LabTabAnalysisConfigurationMetadataKeys>
    </div>
    <div class="subheading font-weight-bold">
      Analysis Parameters
    </div>
    <div class="px-4 mt-2 mb-5">
      <LabTabAnalysisConfigurationAnalysisParameters :lab="lab" :disabled="!isLabManager()"></LabTabAnalysisConfigurationAnalysisParameters>
    </div>
    <div class="subheading font-weight-bold">
        Default Submission Options
    </div>
    <div class="px-4 mt-2">
      <LabTabAnalysisConfigurationSubmissionDefaults :lab="lab" :disabled="!isLabManager()"></LabTabAnalysisConfigurationSubmissionDefaults>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import LabTabAnalysisConfigurationMetadataKeys from "@bugseq-site/app/src/components/app/LabTabAnalysisConfigurationMetadataKeys.vue"
import LabTabAnalysisConfigurationAnalysisParameters from "@bugseq-site/app/src/components/app/LabTabAnalysisConfigurationAnalysisParameters.vue"
import LabTabAnalysisConfigurationSubmissionDefaults from "@bugseq-site/app/src/components/app/LabTabAnalysisConfigurationSubmissionDefaults.vue"
import { readUserProfile } from "@bugseq-site/app/src/store/modules/api/getters";

const ComponentProps = Vue.extend({
  props: {
    lab: Object,
  },
});

@Component({
  components: {
    LabTabAnalysisConfigurationMetadataKeys,
    LabTabAnalysisConfigurationAnalysisParameters,
    LabTabAnalysisConfigurationSubmissionDefaults,
  },
})
export default class LabTabAnalysisConfiguration extends ComponentProps {
  private isLabManager() {
    const user = readUserProfile(this.$store)
    if (!user || !this.lab) {
      return false
    }

    return this.lab.manager_id === user.id
  }
}
</script>

<template>
  <div class="pa-4 mt-4">
    <div class="subheading font-weight-bold">
      Data Access
    </div>
    <div class="px-4 mt-2 mb-5">
      <div class="subheading my-3">
        Control who in the lab has access to data. By default, analysis data is only accessible by the user who submitted the analysis. Only lab managers can adjust this setting.
      </div>
      <v-alert
        :value="!isLabManager()"
        class="font-weight-bold my-3"
        type="info"
        outline
      >
        Only lab managers can update settings for a lab
      </v-alert>
      <v-checkbox
        v-model="lab.auto_share_analyses"
        @change="updateAutoShareAnalyses"
        label="Automatically Share Analyses with Lab Members"
        :disabled="!isLabManager()"
      >
        <template slot="label">
          <span class="mr-2">
            Automatically Share Analyses with Lab Members </span
          ><PopOut icon="help"
            >When any analysis completes, all lab members will get an email with a
            link to access results.</PopOut
          >
        </template>
      </v-checkbox>
    </div>
    <div class="subheading font-weight-bold">
      Notifications
    </div>
    <div class="px-4 mt-2 mb-5">
      <div class="subheading my-3">
        You will always receive an email when an analysis is shared directly with you. You can also receive notifications analyses are shared with the whole lab.
      </div>
      <v-checkbox
        v-model="this.lab.member_preferences.emails_enabled_org_share"
        @change="updateMembership"
        hide-details
        class="mb-1 mt-0"
      >
        <template slot="label">
          <span class="black--text">Receive an Email When an Analysis is Shared with the Entire Lab</span>
        </template>
      </v-checkbox>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import {
  dispatchUpdateLab,
  dispatchGetLabMembership,
  dispatchUpdateLabMembership,
} from "@bugseq-site/app/src/store/modules/api/actions";
import { readUserProfile } from "@bugseq-site/app/src/store/modules/api/getters";
import PopOut from "@bugseq-site/shared/src/components/PopOut.vue";

const ComponentProps = Vue.extend({
  props: {
    lab: Object,
  },
});

@Component({
  components: {
    PopOut,
  }
})
export default class LabTabDataAccessAndNotifications extends ComponentProps {
  private dirty: boolean = false

  private isLabManager() {
    const user = readUserProfile(this.$store)
    if (!user || !this.lab) {
      return false
    }

    return this.lab.manager_id === user.id
  }

  private updateMembership() {
    dispatchUpdateLabMembership(this.$store, {
      id: this.lab.id,
      payload: {
        emails_enabled_org_share: this.lab.member_preferences.emails_enabled_org_share,
      },
    });
  }

  private async updateAutoShareAnalyses() {
    await dispatchUpdateLab(this.$store, {
      id: this.lab.id,
      lab: { auto_share_analyses: this.lab.auto_share_analyses },
    });
    await dispatchGetLabMembership(this.$store); // refresh, because the resp of create doesn't have members
  }
}
</script>

<template>
  <div>
    <div class="subheading my-3">
      These parameters will impact your analysis and results when you submit data.
    </div>
    <v-form>
      <div class="subheading font-weight-bold">Percent Identity to Reference Genome Pass/Fail Threshold:</div>
      <v-text-field
        name="Percent Identity to Reference Genome"
        type="number"
        placeholder="99"
        min="1"
        step="1"
        suffix="%"
        v-model="percentIdentityToReferenceGenomeThreshold"
        v-validate="'between:1,100'"
        :error-messages="errors.first('Percent Identity to Reference Genome')"
        class="input-field"
      ></v-text-field>
      <v-btn
        class="ml-0 text-capitalize"
        @click="updateAnalysisParameters()"
        color="primary"
        :disabled="errors.items.length > 0 || disabled"
      >
        Save
      </v-btn>
    </v-form>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import {
  dispatchUpdateLab,
  dispatchGetLabMembership,
} from "@bugseq-site/app/src/store/modules/api/actions";

const ComponentProps = Vue.extend({
  props: {
    lab: Object,
    disabled: Boolean,
  },
});

@Component
export default class LabTabAnalysisConfigurationAnalysisParameters extends ComponentProps {
  private percentIdentityToReferenceGenomeThreshold: number | null = null

  private mounted() {
    if (this.lab.percent_identity_to_reference_genome_threshold) {
      this.percentIdentityToReferenceGenomeThreshold = this.lab.percent_identity_to_reference_genome_threshold
    }
  }

  private async updateAnalysisParameters() {
    await dispatchUpdateLab(this.$store, {
      id: this.lab.id,
      lab: { percent_identity_to_reference_genome_threshold: this.percentIdentityToReferenceGenomeThreshold },
    });
    await dispatchGetLabMembership(this.$store); // refresh, because the resp of create doesn't have members
  }
}
</script>

<style scoped>
.input-field {
  max-width: 30em;
}
</style>

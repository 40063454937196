<template>
  <div class="mt-3 mb-4">
    <div class="pb-2 font-weight-bold">
      Sample Metadata
    </div>
    <div class="mb-4">
      Metadata is <b>not</b> required. New to sample metadata? Read the <a href="https://docs.bugseq.com/input/#metadata-submission" target="_blank">docs!</a>
    </div>
    <div v-if="shouldShowMetadataForm()">
      <v-layout column>
        <v-flex xs12>
          <div @click="changeInputMethod(InputMethod.IndividualSample)" class="input-method-title">
            <v-icon v-html="inputMethod === InputMethod.IndividualSample ? 'expand_more' : 'chevron_right'"></v-icon>
            Add Individual Samples
          </div>
          <div
            v-if="inputMethod === InputMethod.IndividualSample"
            class="ml-5 mt-2"
          >
            <v-layout column>
              <v-flex xs12 v-if="quickAddSuggestions().length > 0">
                <v-layout row wrap align-center>
                  <div class="mr-2">Quick Add:</div>
                  <v-chip
                    outline
                    color="primary"
                    v-for="sample in quickAddSuggestions()"
                    :key="sample"
                    @click="addSample(sample)"
                  >
                    {{sample}}
                    <v-icon right>add_circle</v-icon>
                  </v-chip>
                  <v-chip
                    outline
                    color="primary"
                    @click="addAllSuggestions()"
                  >
                    Add all
                    <v-icon right>playlist_add_circle</v-icon>
                  </v-chip>
                </v-layout>
              </v-flex>
              <v-flex xs12>
                <v-layout>
                  <v-text-field
                    placeholder="Sample 123"
                    v-model="newSampleName"
                  ></v-text-field>
                  <v-btn
                    @click="addSample(newSampleName)"
                    class="text-capitalize"
                  >Add Sample</v-btn>
                </v-layout>
              </v-flex>
            </v-layout>
          </div>
        </v-flex>
        <v-flex xs12>
          <div @click="changeInputMethod(InputMethod.File)" class="input-method-title">
            <v-icon v-html="inputMethod === InputMethod.File ? 'expand_more' : 'chevron_right'"></v-icon>
            Upload Batch Metadata File
          </div>
          <div
            v-if="inputMethod === InputMethod.File"
            class="ml-5 mt-2"
          >
            <div>
              <v-alert
                type="info"
                class="google-font my-4"
                :value="true"
                outline
              >
                <span class="mr-2">Download a template to fill in Excel:</span>
                <v-btn
                  @click="downloadMetadataTemplate()"
                  class="text-capitalize my-0"
                >
                  Download
                </v-btn>
              </v-alert>
            </div>
            <div>
              <span class="mr-2">Upload:</span>
              <input
                type="file"
                accept=".json,.csv"
                @change="(e) => parseFileUpload(e)"
              ></input>
            </div>
          </div>
        </v-flex>
      </v-layout>
      <v-data-table
        :headers="headers()"
        :items="rows"
        v-if="rows.length > 0"
      >
        <template v-slot:items="props">
          <td>
            {{ props.item.sample }}
          </td>
          <td>
            <v-checkbox
              v-model="props.item.negative_control"
              hide-details
              v-if="!isNegativeControlDisabled(props.item.sample)"
            >
            </v-checkbox>
            <PopOut v-else icon="help">Only one sample can be designated as negative control, which will act as the negative control for all other samples submitted</PopOut>
          </td>
          <td v-for="k in sortedMetadataKeys()">
            <v-text-field
              v-model="props.item[k]"
              placeholder="Value"
              hide-details
              class="py-2"
            ></v-text-field>
          </td>
          <td>
            <v-btn
              flat
              icon
              color="red"
              @click="removeSample(props.item.sample)"
            >
              <v-icon>cancel</v-icon>
            </v-btn>
          </td>
        </template>
      </v-data-table>
      <v-alert
        type="warning"
        class="google-font my-4"
        :value="!!warning"
      >
        {{ warning }}
      </v-alert>
    </div>
    <div v-else>
      <v-btn
        class="text-capitalize mx-0"
        @click="showMetadataForm = true"
      >Add metadata</v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import {stringify} from 'csv-stringify/browser/esm/sync';
import {parse} from 'csv-parse/browser/esm/sync';
import PopOut from "@bugseq-site/shared/src/components/PopOut.vue";

export interface MetadataRow {
    sample: string;
    negative_control: boolean;
    [x: string]: unknown;
}

enum InputMethod {
  IndividualSample,
  File,
  None,
}

function parseCSVBool(raw: string): boolean {
  switch (raw.toLowerCase()) {
  case 'true':
  case '1':
    return true
  case 'false':
  case '0':
  case '':
    return false
  }

  throw new Error(`Unrecognized boolean: ${raw}`)
}

function parseCSVRawRow(rawRow: string[], headers: string[]): MetadataRow {
  if (rawRow.length !== headers.length) {
    throw new Error(`Headers do not match row, headers: ${headers}, row: ${rawRow}`)
  }
  const sampleIdx = headers.findIndex(h => h === "sample")
  if (sampleIdx === -1) {
    throw new Error('"sample" header not found')
  }
  const negativeControlIdx = headers.findIndex(h => h === "negative_control")

  const row: MetadataRow = {
    sample: rawRow[sampleIdx],
    negative_control: negativeControlIdx === -1 ? false : parseCSVBool(rawRow[negativeControlIdx]),
  }
  for (let idx = 0; idx < headers.length; idx++) {
    if (idx === sampleIdx || idx === negativeControlIdx) {
      continue
    }
    row[headers[idx]] = rawRow[idx]
  }

  return row
}

const SampleMetadataFormProps = Vue.extend({
  props: {
    "sampleNames": {
      type: Set<string>,
      required: true,
    },
    "labMetadataKeys": {
      type: Set<string>,
      required: true,
    },
    "rows": {
      type: Array<MetadataRow>,
      required: true,
    }
  }
})

@Component({
  data() {
    return {
      InputMethod,
    };
  },
  components: {
    PopOut,
  }
})
export default class SampleMetadataForm extends SampleMetadataFormProps {
  private showMetadataForm: boolean = false
  private newSampleName: string = "";
  private inputMethod: InputMethod = InputMethod.IndividualSample
  private warning: string | null = null

  private sortedMetadataKeys(): string[] {
    return [...this.labMetadataKeys].sort()
  }

  private shouldShowMetadataForm(): boolean {
    if (this.sortedMetadataKeys().length > 0) {
      return true
    }

    return this.showMetadataForm
  }

  private headers(): {text: string, value: string}[] {
    return [
      { text: 'Sample', value: 'sample', sortable: false },
      { text: 'Negative Control', value: 'negative_control', sortable: false },
    ].concat(
      this.sortedMetadataKeys().map(k => ({ text: k, value: k, sortable: false }))
    ).concat(
      [
        { text: '', value: 'options', sortable: false},
      ]
    )
  }

  private quickAddSuggestions() {
    return [...this.sampleNames].filter(s => !this.rows.some(r => r.sample === s)).sort().slice(0, 5)
  }

  private newRow(sample: string): MetadataRow {
    const row = {sample, negative_control: false}
    for (const k of this.sortedMetadataKeys()) {
      row[k] = ""
    }

    return row
  }

  private addAllSuggestions() {
    [...this.sampleNames].filter(s => !this.rows.some(r => r.sample === s)).sort().forEach(this.addSample)
  }

  private isNegativeControlDisabled(sample: string): boolean {
    const neg = this.rows.find(r => r.negative_control)
    if (!neg) {
      return false
    }

    // allow them to disable negative-control once selected
    if (neg.sample === sample) {
      return false
    }

    return true
  }

  private addSample(sampleName: string) {
    if (!sampleName) {
      this.warning = "Must enter sample name"
      return
    }

    const existingNames = new Set(this.rows.map(r => r.sample))
    if (existingNames.has(sampleName)) {
      this.warning = `Duplicate sample: ${sampleName}`
      return
    }

    this.$emit("add-samples", [this.newRow(sampleName)])

    // if adding from the text field, reset it.
    if (sampleName === this.newSampleName) {
      this.newSampleName = ""
    }
    this.warning = null
  }

  private removeSample(sampleName: string) {
    this.$emit("remove-samples", new Set([sampleName]))
  }

  private async parseFileUpload(e) {
    this.$emit("remove-samples", new Set(this.rows.map(r => r.sample)))

    try {
      const target = e.target as HTMLInputElement;
      const file = target.files?.[0]
      if (!file) {
        throw new Error("No file found")
      }
      const content = await file.text()
      const rawRows = parse(content)
      if (rawRows.length <= 1) {
        throw new Error('No header line found')
      }
      const headerRow = rawRows[0];
      if (!headerRow.includes("sample")) {
        throw new Error('"sample" must be present as a header')
      }

      const allowedHeaders = ["sample", "negative_control", ...this.sortedMetadataKeys()]
      for (const header of headerRow) {
        if (!allowedHeaders.includes(header)) {
          throw new Error(`Unrecognized header "${header}", allowed headers: ${allowedHeaders.join(", ")}`)
        }
      }

      const dataRows = rawRows.slice(1)

      const parsedRows = dataRows.map(rawRow => parseCSVRawRow(rawRow, headerRow))
      const parsedSampleNames = new Set<string>()
      for (const row of parsedRows) {
        if (parsedSampleNames.has(row.sample)) {
          throw new Error(`Found duplicate sample name: ${row.sample}`)
        }
        parsedSampleNames.add(row.sample)
      }

      this.$emit("add-samples", parsedRows)

      this.warning = null
    } catch (e) {
      if (e instanceof Error) {
        this.warning = e.message
      } else {
        throw e
      }
    }
  }

  private changeInputMethod(inputMethod: InputMethod) {
    if (inputMethod === this.inputMethod) {
      this.inputMethod = InputMethod.None
      return
    }
    this.inputMethod = inputMethod
  }

  private downloadMetadataTemplate() {
    const headers = ["sample", "negative_control", ...this.sortedMetadataKeys()]
    const sampleRows = [...this.sampleNames].sort().map(sampleName => headers.map(header => {
      if (header === "sample") {
        return sampleName
      }
      if (header === "negative_control") {
        return false
      }
      return ""
    }))
    const contents = stringify(
      sampleRows,
      {
        cast: {
          boolean: (v) => v.toString()
        },
        columns: headers,
        header: true,
      },
    )

    const blob = new Blob([contents], { type: 'text/plain' });
    const link = document.createElement("a");
    link.setAttribute('download', 'bugseq-sample-metadata-template.csv');
    link.href = window.URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
</script>

<style scoped>
.input-method-title {
  cursor: pointer;
}
</style>

<template>
  <v-expansion-panel
    expand
    class="mt-2 mb-4"
    v-if="uploader.inProgress.length > 0
        || uploader.succeeded.length > 0
        || uploader.failed.length > 0"
  >
    <v-expansion-panel-content lazy v-if="uploader.inProgress.length">
      <template v-slot:header>
        <v-layout align-center>
          {{ name }} In Progress
          <v-chip color="blue lighten-4" class="ml-3">{{
            uploader.inProgress.length
          }}</v-chip>
          <v-progress-circular
            indeterminate
            color="primary"
            class="ml-3"
            :size="30"
          ></v-progress-circular>
        </v-layout>
      </template>
      <v-card>
        <v-card-text>
          <v-data-table
            :headers="inProgressHeaders"
            :items="uploader.inProgress"
          >
            <template v-slot:items="props">
              <td>{{ props.item.toString() }}</td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-expansion-panel-content>
    <v-expansion-panel-content lazy v-if="uploader.succeeded.length">
      <template v-slot:header>
        <v-layout align-center>
          {{ name }} Succeeded
          <v-chip color="green lighten-4" class="ml-3">{{
            uploader.succeeded.length
          }}</v-chip>
        </v-layout>
      </template>
      <v-card>
        <v-card-text>
          <v-data-table
            :headers="successHeaders"
            :items="uploader.succeeded"
          >
            <template v-slot:items="props">
              <td>{{ props.item.toString() }}</td>
              <td class="text-xs-left">
                <v-icon small @click="deleteSucceededFile(props.item.id)">
                  delete
                </v-icon>
              </td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-expansion-panel-content>
    <v-expansion-panel-content v-if="uploader.failed.length">
      <template v-slot:header>
        <v-layout align-center>
          {{ name }} Failed
          <v-chip color="red lighten-4" class="ml-3">{{
            uploader.failed.length
          }}</v-chip>
        </v-layout>
      </template>
      <v-card>
        <v-card-text>
          <v-btn
            color="primary"
            class="text-capitalize"
            block
            @click="deleteAllFailedFiles"
          >
            Remove all failed
          </v-btn>
          <v-data-table
            :headers="failedHeaders"
            :items="uploader.failed"
          >
            <template v-slot:items="props">
              <td>{{ props.item.toString() }}</td>
              <td class="text-xs-left">
                <v-icon small @click="deleteFailedFile(props.item.id)">
                  delete
                </v-icon>
              </td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";

const Props = Vue.extend({
  props: {
    "name": {
      type: String,
    },
    "uploader": {
      type: Object,
    }
  }
})

@Component
export default class UploadStatusPanel extends Props {
  private inProgressHeaders = [{ text: "Name", value: "name" }];
  private successHeaders = [{ text: "Name", value: "name" }];

  // weirdly, vuetify uses "name" as the value: https://v15.vuetifyjs.com/en/components/data-tables
  private failedHeaders = [
    { text: "Name", value: "name" },
    { text: "Actions", value: "name", sortable: false },
  ];

  public deleteSucceededFile(id) {
    this.uploader.deleteSucceeded(id);
  }

  public deleteFailedFile(id) {
    this.uploader.deleteFailed(id);
  }

  public deleteAllFailedFiles() {
    const failedIds = this.uploader.failed.map(u => u.id)
    for (const failedId of failedIds) {
      this.deleteFailedFile(failedId)
    }
  }
}
</script>

import { components } from '@bugseq-site/app/src/lib/api/api'

export interface InviteLinkParameters {
  firstName: string
  lastName: string
  affiliation: string
  signupCode: string
  terms: boolean
}

export interface ComboboxField<Type> {
  text: string
  value: Type
}

export const moleculeTypes: Array<ComboboxField<components['schemas']['MoleculeType']>> = [
  { text: 'DNA', value: 'DNA' },
  { text: 'cDNA/RNA', value: 'RNA' },
  { text: 'DNA+RNA (Total Nucleic Acid)', value: 'TNA' }
]

export const sampleTypes: Array<ComboboxField<components['schemas']['app__models__job_run_options__SampleType']>> = [
  { text: 'Abscess', value: 'ABSCESS' },
  { text: 'Blood', value: 'BLOOD' },
  { text: 'Environmental', value: 'ENVIRONMENTAL' },
  { text: 'Generic (default)', value: 'GENERIC' },
  { text: 'Isolate (eg. Bacteria or C. auris)', value: 'BACTERIAL_ISOLATE' },
  {
    text: 'Respiratory - Lower (eg. Sputum, BAL, tracheal aspirate, etc.)',
    value: 'RESPIRATORY_LOWER'
  },
  {
    text: 'Respiratory - Upper (eg. Nasopharyngeal, oropharyngeal, etc.)',
    value: 'RESPIRATORY_UPPER'
  },
  {
    text: 'Cerebrospinal fluid (CSF)',
    value: 'CEREBROSPINAL_FLUID'
  },
  {
    text: 'Other sterile fluid (eg. synovial fluid, peritoneal fluid, etc.)',
    value: 'STERILE_SAMPLE'
  },
  { text: 'Stool', value: 'STOOL' },
  { text: 'Urine', value: 'URINE' },
  { text: 'Wastewater', value: 'WASTEWATER' },
  { text: 'Wound - Deep', value: 'WOUND_DEEP' },
  { text: 'Wound - Superficial', value: 'WOUND_SUPERFICIAL' }
]

export const platforms: Array<ComboboxField<components['schemas']['Platform']>> = [
  { text: 'Nanopore', value: 'NANOPORE' },
  { text: 'Illumina (Paired End)', value: 'ILLUMINA' },
  { text: 'Illumina (Single End)', value: 'ILLUMINA_SINGLE_END' },
  { text: 'PacBio', value: 'PACBIO' },
  { text: 'Ion Torrent', value: 'ION_TORRENT' }
]

export const kits: Map<components['schemas']['Platform'], Array<ComboboxField<components['schemas']['Kit']>>> = new Map([
  [
    'NANOPORE',
    [
      {
        text: 'MinION/GridION/Flongle - R10.4/R10.4.1',
        value: 'MINION_R10_4_1'
      },
      { text: 'MinION/GridION/Flongle -  R9.4.1', value: 'MINION_R9_4_1' },
      {
        text:
            'MinION/GridION/Flongle - R9.4.1 Super Accuracy Basecalling',
        value: 'MINION_Q20'
      },
      { text: 'MinION/GridION - R10.3', value: 'MINION_R10_3' },
      { text: 'PromethION - R9.4.1', value: 'PROMETHION_R9_4_1' },
      {
        text: 'PromethION - R9.4.1 Super Accuracy Basecalling',
        value: 'PROMETHION_Q20'
      },
      { text: 'PromethION - R10.4/R10.4.1', value: 'PROMETHION_R10_4_1' },
      { text: 'Other', value: 'UNKNOWN' }
    ]
  ],
  [
    'PACBIO',
    [
      { text: 'HiFi', value: 'HIFI' },
      { text: 'Other', value: 'UNKNOWN' }
    ]
  ]
])
